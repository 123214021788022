export default () => {
  const languageCheckerRUUrl =
    process.env.APP_ENV === 'prod' ? 'https://ru.eventrocks.kz' : 'https://ru-test.eventrocks.kz';

  const ruLink = document.getElementById('lang_switcher__inner__ru');
  if (ruLink) {
    ruLink.setAttribute('href', languageCheckerRUUrl);
  }

  let switchBtn = document.querySelector('.lang_switcher');

  switchBtn.addEventListener('click', (e) => {
    e.stopPropagation();
    switchBtn.classList.toggle('open');
  });

  window.addEventListener('click', () => {
    if (switchBtn.classList.contains('open')) switchBtn.classList.remove('open');
  });
};
